import { datadogRum, type RumInitConfiguration } from "@datadog/browser-rum";
import { useEffect } from "react";

interface Configuration
  extends Pick<RumInitConfiguration, "clientToken" | "sessionSampleRate"> {
  version: string;
  service: string;
  env: "playground" | "dev" | "staging" | "prod";
}

const useDatadogRum = (
  config: Configuration,
  isChina: boolean
): typeof datadogRum => {
  useEffect(() => {
    if (isChina || config.env === "playground") return;

    const initializeDatadogRum = () => {
      datadogRum.init({
        applicationId: "949add01-4dba-4862-ab78-354005ab1d95",
        clientToken: config.clientToken,
        service: config.service,
        version: config.version,
        env: config.env,
        sessionSampleRate: config.sessionSampleRate ?? 100,
        sessionReplaySampleRate: 0,
        site: "datadoghq.eu",
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        silentMultipleInit: true,
      });

      if (window._dotcomRUM?.errors && window._dotcomRUM.pushToQueue) {
        for (const error of window._dotcomRUM.errors) {
          datadogRum.addError(error);
        }

        window.removeEventListener("error", window._dotcomRUM.pushToQueue);
      }
    };

    const hasApprovedPerformanceCookies = () => {
      return window.OnetrustActiveGroups?.includes("2");
    };

    const callback = () => {
      if (hasApprovedPerformanceCookies()) {
        initializeDatadogRum();
        window.removeEventListener("OTConsentApplied", callback);
      }
    };

    if (hasApprovedPerformanceCookies()) {
      initializeDatadogRum();
    } else {
      // Cookie is not set yet we attach an eventlistener to reverify when cookie set/changes
      window.addEventListener("OTConsentApplied", callback, {
        once: true,
      });
      return () => {
        window.removeEventListener("OTConsentApplied", callback);
      };
    }
  }, [
    config.service,
    config.version,
    config.env,
    config.sessionSampleRate,
    config.clientToken,
    isChina,
  ]);
  return datadogRum;
};

export default useDatadogRum;
