const supportDialogMarkets = new Set([
  "fr-be",
  "nl-be",
  "de",
  "nl",
  "no",
  "de-ch",
  "fr-ch",
  "it-ch",
  "se",
  "uk",
  "en-ca",
  "fr-ca",
  "us",
  "fi",
  "dk",
  "at",
  "fr-lu",
  "au",
  "kr",
  "ie",
  "es",
  "pt",
  "it",
]);
export default supportDialogMarkets;
