[
  {
    "apiRegion": "global",
    "cmsLocale": "fr_BE",
    "countryCode": "be",
    "dateFormat": "DD.MM.YYYY",
    "features": [
      "enableLogin"
    ],
    "languageCode": "fr",
    "languageName": "Français",
    "locale": "fr-be",
    "marketName": "Belgique | Français",
    "marketType": "PSU",
    "region": "Europe"
  },
  {
    "apiRegion": "global",
    "cmsLocale": "nl_BE",
    "countryCode": "be",
    "dateFormat": "DD.MM.YYYY",
    "features": [
      "enableLogin"
    ],
    "languageCode": "nl",
    "languageName": "Vlaams",
    "locale": "nl-be",
    "marketName": "België | Nederlands",
    "marketType": "PSU",
    "region": "Europe"
  },
  {
    "apiRegion": "global",
    "cmsLocale": "da_DK",
    "countryCode": "dk",
    "dateFormat": "DD.MM.YYYY",
    "features": [
      "enableLogin"
    ],
    "languageCode": "da",
    "languageName": "Dansk",
    "locale": "dk",
    "marketName": "Danmark",
    "marketType": "PSU",
    "region": "Europe"
  },
  {
    "apiRegion": "global",
    "cmsLocale": "da_DK",
    "countryCode": "fo",
    "dateFormat": "DD.MM.YYYY",
    "features": [],
    "languageCode": "en",
    "languageName": "English",
    "locale": "fo",
    "marketName": "Føroyar",
    "marketType": "Importer",
    "region": "Europe"
  },
  {
    "apiRegion": "global",
    "cmsLocale": "de_DE",
    "countryCode": "de",
    "dateFormat": "DD.MM.YYYY",
    "features": [
      "enableLogin"
    ],
    "languageCode": "de",
    "languageName": "Deutsch",
    "locale": "de",
    "marketName": "Deutschland",
    "marketType": "PSU",
    "region": "Europe"
  },
  {
    "apiRegion": "global",
    "cmsLocale": "es_ES",
    "countryCode": "es",
    "dateFormat": "DD.MM.YYYY",
    "features": [
      "enableLogin"
    ],
    "languageCode": "es",
    "languageName": "Castilian",
    "locale": "es",
    "marketName": "España",
    "marketType": "PSU",
    "region": "Europe"
  },
  {
    "apiRegion": "global",
    "cmsLocale": "is_IS",
    "countryCode": "is",
    "dateFormat": "DD.MM.YYYY",
    "features": [],
    "languageCode": "is",
    "languageName": "Íslenska",
    "locale": "is-is",
    "marketName": "Ísland",
    "marketType": "Importer",
    "region": "Europe"
  },
  {
    "apiRegion": "global",
    "cmsLocale": "en_IE",
    "countryCode": "ie",
    "dateFormat": "DD.MM.YYYY",
    "features": [
      "enableLogin"
    ],
    "languageCode": "en",
    "languageName": "English",
    "locale": "ie",
    "marketName": "Ireland",
    "marketType": "S-PSU",
    "region": "Europe"
  },
  {
    "apiRegion": "global",
    "cmsLocale": "it_IT",
    "countryCode": "it",
    "dateFormat": "DD.MM.YYYY",
    "features": [
      "enableLogin"
    ],
    "languageCode": "it",
    "languageName": "Italiano",
    "locale": "it",
    "marketName": "Italia",
    "marketType": "PSU",
    "region": "Europe"
  },
  {
    "apiRegion": "global",
    "cmsLocale": "fr_LU",
    "countryCode": "lu",
    "dateFormat": "DD.MM.YYYY",
    "features": [
      "enableLogin"
    ],
    "languageCode": "fr",
    "languageName": "Français",
    "locale": "fr-lu",
    "marketName": "Luxembourg",
    "marketType": "S-PSU",
    "region": "Europe"
  },
  {
    "apiRegion": "global",
    "cmsLocale": "nl_NL",
    "countryCode": "nl",
    "dateFormat": "DD.MM.YYYY",
    "features": [
      "enableLogin"
    ],
    "languageCode": "nl",
    "languageName": "Nederlands",
    "locale": "nl",
    "marketName": "Nederland",
    "marketType": "PSU",
    "region": "Europe"
  },
  {
    "apiRegion": "global",
    "cmsLocale": "nb_NO",
    "countryCode": "no",
    "dateFormat": "YYYY.MM.DD",
    "features": [
      "enableLogin"
    ],
    "languageCode": "nb",
    "languageName": "Norsk",
    "locale": "no",
    "marketName": "Norge",
    "marketType": "PSU",
    "region": "Europe"
  },
  {
    "apiRegion": "global",
    "cmsLocale": "pt_PT",
    "countryCode": "pt",
    "dateFormat": "DD.MM.YYYY",
    "features": [
      "enableLogin"
    ],
    "languageCode": "pt",
    "languageName": "Portuguese",
    "locale": "pt",
    "marketName": "Portugal",
    "marketType": "S-PSU",
    "region": "Europe"
  },
  {
    "apiRegion": "global",
    "cmsLocale": "de_CH",
    "countryCode": "ch",
    "dateFormat": "DD.MM.YYYY",
    "features": [
      "enableLogin"
    ],
    "languageCode": "de",
    "languageName": "Deutsch",
    "locale": "de-ch",
    "marketName": "Schweiz | Deutsch",
    "marketType": "PSU",
    "region": "Europe"
  },
  {
    "apiRegion": "global",
    "cmsLocale": "fr_CH",
    "countryCode": "ch",
    "dateFormat": "DD.MM.YYYY",
    "features": [
      "enableLogin"
    ],
    "languageCode": "fr",
    "languageName": "Français",
    "locale": "fr-ch",
    "marketName": "Suisse | Français",
    "marketType": "PSU",
    "region": "Europe"
  },
  {
    "apiRegion": "global",
    "cmsLocale": "it_CH",
    "countryCode": "ch",
    "dateFormat": "DD.MM.YYYY",
    "features": [
      "enableLogin"
    ],
    "languageCode": "it",
    "languageName": "Italiano",
    "locale": "it-ch",
    "marketName": "Svizzera | Italiano",
    "marketType": "PSU",
    "region": "Europe"
  },
  {
    "apiRegion": "global",
    "cmsLocale": "fi_FI",
    "countryCode": "fi",
    "dateFormat": "DD.MM.YYYY",
    "features": [
      "enableLogin"
    ],
    "languageCode": "fi",
    "languageName": "Suomalainen",
    "locale": "fi",
    "marketName": "Suomi",
    "marketType": "PSU",
    "region": "Europe"
  },
  {
    "apiRegion": "global",
    "cmsLocale": "sv_SE",
    "countryCode": "se",
    "dateFormat": "YYYY.MM.DD",
    "features": [
      "enableLogin"
    ],
    "languageCode": "sv",
    "languageName": "Svenska",
    "locale": "se",
    "marketName": "Sverige",
    "marketType": "PSU",
    "region": "Europe"
  },
  {
    "apiRegion": "global",
    "cmsLocale": "en_GB",
    "countryCode": "gb",
    "dateFormat": "DD.MM.YYYY",
    "features": [
      "enableLogin"
    ],
    "languageCode": "en",
    "languageName": "English",
    "locale": "uk",
    "marketName": "United Kingdom",
    "marketType": "PSU",
    "region": "Europe"
  },
  {
    "apiRegion": "global",
    "cmsLocale": "de_AT",
    "countryCode": "at",
    "dateFormat": "DD.MM.YYYY",
    "features": [
      "enableLogin"
    ],
    "languageCode": "de",
    "languageName": "Deutsch",
    "locale": "at",
    "marketName": "Österreich",
    "marketType": "PSU",
    "region": "Europe"
  },
  {
    "apiRegion": "global",
    "cmsLocale": "en_CA",
    "countryCode": "ca",
    "dateFormat": "MM.DD.YYYY",
    "features": [
      "enableLogin"
    ],
    "languageCode": "en",
    "languageName": "English",
    "locale": "en-ca",
    "marketName": "Canada | English",
    "marketType": "PSU",
    "region": "North America"
  },
  {
    "apiRegion": "global",
    "cmsLocale": "fr_CA",
    "countryCode": "ca",
    "dateFormat": "YYYY.MM.DD",
    "features": [
      "enableLogin"
    ],
    "languageCode": "fr",
    "languageName": "Français",
    "locale": "fr-ca",
    "marketName": "Canada | Français",
    "marketType": "PSU",
    "region": "North America"
  },
  {
    "apiRegion": "global",
    "cmsLocale": "en_US",
    "countryCode": "us",
    "dateFormat": "MM.DD.YYYY",
    "features": [
      "enableLogin"
    ],
    "languageCode": "en",
    "languageName": "English",
    "locale": "us",
    "marketName": "United States",
    "marketType": "PSU",
    "region": "North America"
  },
  {
    "apiRegion": "global",
    "cmsLocale": "en_AU",
    "countryCode": "au",
    "dateFormat": "DD.MM.YYYY",
    "features": [
      "enableLogin"
    ],
    "languageCode": "en",
    "languageName": "English",
    "locale": "au",
    "marketName": "Australia",
    "marketType": "PSU",
    "region": "Asia-Pacific"
  },
  {
    "apiRegion": "global",
    "cmsLocale": "en_HK",
    "countryCode": "hk",
    "dateFormat": "DD.MM.YYYY",
    "features": [],
    "languageCode": "en",
    "languageName": "English",
    "locale": "hk",
    "marketName": "Hong Kong - China",
    "marketType": "Importer",
    "region": "Asia-Pacific"
  },
  {
    "apiRegion": "global",
    "cmsLocale": "ko_KR",
    "countryCode": "kr",
    "dateFormat": "YYYY.MM.DD",
    "features": [
      "enableLogin"
    ],
    "languageCode": "ko",
    "languageName": "한국어",
    "locale": "kr",
    "marketName": "대한민국",
    "marketType": "PSU",
    "region": "Asia-Pacific"
  },
  {
    "apiRegion": "global",
    "cmsLocale": "en_NZ",
    "countryCode": "nz",
    "dateFormat": "DD.MM.YYYY",
    "features": [],
    "languageCode": "en",
    "languageName": "English",
    "locale": "nz",
    "marketName": "New Zealand",
    "marketType": "Importer",
    "region": "Asia-Pacific"
  },
  {
    "apiRegion": "global",
    "cmsLocale": "en_SG",
    "countryCode": "sg",
    "dateFormat": "DD.MM.YYYY",
    "features": [],
    "languageCode": "en",
    "languageName": "English",
    "locale": "en-sg",
    "marketName": "Singapore",
    "marketType": "Importer",
    "region": "Asia-Pacific"
  },
  {
    "apiRegion": "china",
    "cmsLocale": "zh_CN",
    "countryCode": "cn",
    "dateFormat": "YYYY.MM.DD",
    "features": [],
    "languageCode": "zh",
    "languageName": "中文",
    "locale": "zh-cn",
    "marketName": "中国",
    "marketType": "PSU",
    "region": "Asia-Pacific"
  },
  {
    "apiRegion": "global",
    "cmsLocale": "en_IL",
    "countryCode": "il",
    "dateFormat": "DD.MM.YYYY",
    "features": [],
    "languageCode": "en",
    "languageName": "English",
    "locale": "en-il",
    "marketName": "Israel",
    "marketType": "Importer",
    "region": "Middle-East"
  },
  {
    "apiRegion": "global",
    "cmsLocale": "he_IL",
    "countryCode": "il",
    "dateFormat": "DD.MM.YYYY",
    "features": [
      "enableRTL"
    ],
    "languageCode": "he",
    "languageName": "עברית",
    "locale": "he-il",
    "marketName": "ישראל",
    "marketType": "Importer",
    "region": "Middle-East"
  },
  {
    "apiRegion": "global",
    "cmsLocale": "en_KW",
    "countryCode": "kw",
    "dateFormat": "DD.MM.YYYY",
    "features": [],
    "languageCode": "en",
    "languageName": "English",
    "locale": "en-kw",
    "marketName": "Kuwait",
    "marketType": "Importer",
    "region": "Middle-East"
  },
  {
    "apiRegion": "global",
    "cmsLocale": "en_AE",
    "countryCode": "ae",
    "dateFormat": "DD.MM.YYYY",
    "features": [],
    "languageCode": "en",
    "languageName": "English",
    "locale": "en-ae",
    "marketName": "United Arab Emirates",
    "marketType": "Importer",
    "region": "Middle-East"
  },
  {
    "apiRegion": "global",
    "cmsLocale": "ar_AE",
    "countryCode": "ae",
    "dateFormat": "DD.MM.YYYY",
    "features": [
      "enableRTL"
    ],
    "languageCode": "ar",
    "languageName": "عربي",
    "locale": "ar-ae",
    "marketName": "الإمارات العربية المتحدة",
    "marketType": "Importer",
    "region": "Middle-East"
  },
  {
    "apiRegion": "global",
    "cmsLocale": "glb",
    "countryCode": "global",
    "dateFormat": "YYYY.MM.DD",
    "features": [],
    "languageCode": "en",
    "languageName": "English",
    "locale": "global",
    "marketName": "Global",
    "marketType": "Importer",
    "region": "Global"
  }
]
