import productionData from "../../markets.production.json";
import stagingData from "../../markets.staging.json";

export enum ApiRegion {
  Global = "global",
  China = "china",
}

export enum MarketRegion {
  NorthAmerica = "North America",
  AsiaPacific = "Asia-Pacific",
  Europe = "Europe",
  MiddleEast = "Middle-East",
  Global = "Global",
}

export enum MarketType {
  Importer = "Importer",
  PSU = "PSU",
  SPSU = "S-PSU",
}

export interface Market {
  // The locale parameter in the url
  locale: string;
  // The marketName of the market in its main language
  marketName: string;
  // The marketType of the market
  marketType: MarketType;
  // The ISO-3166-1 alpha-2 country code identifier
  countryCode: string;
  // The locale to be used in DatoCMS context
  cmsLocale: string;
  // The ISO-639 alpha-2 language code identifier
  languageCode: string;
  // The local marketName of the language
  languageName: string;
  // The market region
  region: MarketRegion;
  // The API region to target
  apiRegion: ApiRegion;
  // Format of the date used by the market
  dateFormat: string;
  // Markets specific features
  features: string[];
}

const englishPreviewMarket: Market = {
  apiRegion: ApiRegion.Global,
  cmsLocale: "en",
  countryCode: "english",
  dateFormat: "YYYY.MM.DD",
  features: [],
  languageCode: "en",
  languageName: "English",
  locale: "en",
  marketName: "English Preview",
  marketType: MarketType.Importer,
  region: MarketRegion.Europe,
};

export const productionMarkets = productionData as Market[];
export const stagingMarkets = [
  ...stagingData,
  englishPreviewMarket,
] as Market[];
